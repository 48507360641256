<template>
  <div>
    <!------------ START: FieldWrapper ------------>
    <FieldWrapper :field="field">
      <!------------ START: Direct input switch ------------>
      <template #label-append>
        <b-form-checkbox v-model="directInput" switch>
          <span class="text-muted">{{ $t("formHelper.directInput") }}</span>
        </b-form-checkbox>
      </template>
      <!------------ END: Direct input switch ------------>
      <!------------ START: Slot default ------------>
      <template #default>
        <!------------ START: Direct input ------------>
        <div v-if="directInput">
          <input
            v-model="directInputJson"
            type="text"
            class="form-control"
            :class="validationClass"
          />
        </div>
        <!------------ END: Direct input ------------>
        <!------------ START: Field input ------------>
        <div v-else>
          <!------------ START: Json entry rows ------------>
          <div
            v-for="(entry, i) in json"
            :key="i"
            :class="i === json.length - 1 ? 'mb-3' : 'mb-1'"
          >
            <!------------ START: Field columns ------------>
            <div class="row align-items-center flex-nowrap my-0 mr-6">
              <!------------ START: Key field ------------>
              <div class="col-6 py-0 px-1">
                <input
                  v-model="json[i][0]"
                  type="text"
                  class="form-control"
                  :disabled="isDisabled"
                  :placeholder="getSnippet(field.fields[0].name)"
                  @input="updateJson(json[i][0], i, 0)"
                />
              </div>
              <!------------ END: Key field ------------>
              <!------------ START: Value field ------------>
              <div class="col-6 py-0 px-1">
                <div class="input-group">
                  <ContentEditable
                    :ref="`value-${i}`"
                    :default-value="json[i][1]"
                    :placeholder="getSnippet(field.fields[1].name)"
                    :is-disabled="isDisabled"
                    highlight="all"
                    @input="val => updateJson(val, i, 1)"
                    @focus="focusField(i)"
                    @esc="showVariables = !showVariables"
                  />
                  <div class="input-group-append">
                    <span
                      v-b-tooltip.noninteractive.html.top="returnTypeText(i)"
                      class="input-group-text"
                      :class="{ 'cursor-pointer': emptyOrNull(i) }"
                      @click="toggleEmptyType(i)"
                    >
                      <i :class="returnTypeIcon(i)" />
                      <i
                        v-if="emptyOrNull(i)"
                        class="fal fa-rotate toggle-empty"
                      />
                    </span>
                  </div>
                </div>
              </div>
              <!------------ END: Value field ------------>
              <!------------ START: Remove field button ------------>
              <div class="col-auto py-0">
                <i
                  class="fal fa-xmark icon-lg py-2 px-1 ml-n3"
                  :class="{ 'cursor-pointer text-hover-primary': !isDisabled }"
                  @click="removeField(i)"
                />
              </div>
              <!------------ END: Remove field button ------------>
            </div>
            <!------------ END: Field columns ------------>
          </div>
          <!------------ END: Json entry rows ------------>
          <!------------ START: Add field button ------------>
          <div
            class="btn btn-link text-muted p-0"
            :class="[
              isDisabled ? 'disabled' : 'cursor-pointer text-hover-primary'
            ]"
            @click="addField"
          >
            <i class="fal fa-plus" />
            {{ $t("formHelper.jsonAddField") }}
          </div>
          <!------------ END: Add field button ------------>
        </div>
        <!------------ END: Field input ------------>
      </template>
      <!------------ END: Slot default ------------>
    </FieldWrapper>
    <!------------ END: FieldWrapper ------------>
    <!------------ START: Variables dropdown ------------>
    <VariablesDropdown
      v-if="enableVariables"
      v-model="showVariables"
      :filter="focusedFieldValue"
      :el="focusedField.el"
      :input-el="focusedField.el"
      @select="setVariable"
    />
    <!------------ END: Variables dropdown ------------>
  </div>
</template>

<script>
import { base } from "@/components/Tools/FormHelper/Helper/mixins";
import FieldWrapper from "@/components/Tools/FormHelper/Components/FieldWrapper";
import VariablesDropdown from "@/components/Tools/FormHelper/Components/VariablesDropdown/VariablesDropdown";
import {getReturnType, getValidations, stringify, typeCast} from "@/components/Tools/FormHelper/Helper/functions";
import _ from "lodash";
import ContentEditable from "@/components/Tools/FormHelper/Components/ContentEditable";
import {returnTypeIcons} from "@/components/Tools/FormHelper/Helper/constants";

export default {
  components: { ContentEditable, VariablesDropdown, FieldWrapper },
  mixins: [base],
  props: {},
  data() {
    return {
      // Direct input toggle
      directInput: false,
      // Always validate json, merge with given validators
      fieldValidations: Object.assign(
        { json: true },
        this.field.validations ?? {}
      ),
      // Show variables dropdown
      showVariables: false,
      // Get focused field
      focusedField: {
        // Entries key of focused field
        entry: 0,
        // HTML Element to attach dropdown to
        el: undefined
      }
    };
  },
  validations() {
    return {
      value: getValidations(
        this.fieldValidations,
        this.enableVariables,
        this.field.type
      )
    };
  },
  computed: {
    // Computed getter/setter for json input
    json: {
      get: function () {
        // Get current value or empty object as fallback
        let obj = typeof this.value === "object" ? this.value : {};
        // Return entries of object (for render and manipulation reasons)
        return Object.entries(obj).map(entry => {
          // If value of entry is of type object
          if (typeof entry[1] === "object") {
            // JSON stringify value to prevent displaying "[object Object]"
            entry[1] = JSON.stringify(entry[1]);
          } else {
            entry[1] = stringify(entry[1]);
          }
          return entry;
        });
      },
      set: function (value) {
        // Set model to object from given entries
        this.value = Object.fromEntries(value);
        // Trigger validation
        this.$v.value.$touch();
        // Emit updated value
        this.onInput();
      }
    },
    directInputJson: {
      get: function () {
        // Return object as JSON stringified
        return typeof this.value === "string"
          ? this.value
          : JSON.stringify(this.value);
      },
      // Use debounce function for setting (because cursor jumps after setting)
      set: _.debounce(function (value) {
        try {
          // Try to parse given string to json
          this.$v.value.$model = JSON.parse(value);
        } catch (e) {
          // If it fails, set string as model
          this.$v.value.$model = value;
        }
        // Trigger validation
        this.$v.value.$touch();
        // Emit updated value
        this.onInput();
      }, 1000)
    },
    focusedFieldValue: function () {
      // Return current value of focused input
      return stringify(this.json[this.focusedField.entry]?.[1]);
    }
  },
  mounted() {},
  methods: {
    // Add field row
    addField() {
      if (this.isDisabled) {
        return;
      }
      // Get entries from value
      let entries = Object.entries(this.value);
      // Add empty entry
      entries.push(["", ""]);
      // Set new entries
      this.value = Object.fromEntries(entries);
    },
    // Remove entry from value
    removeField(index) {
      if (this.isDisabled) {
        return;
      }
      // Get entries from value
      let entries = Object.entries(this.value);
      // Remove entry from array
      entries.splice(index, 1);
      // Set updated entries
      this.json = entries;
    },
    // Update value of entry manually
    // (Because Vue does not watch nested values)
    updateJson(value, i, j) {
      let entries = Object.entries(this.value);
      entries[i][j] = j === 1 ? typeCast(value) : value;
      this.json = entries;
    },
    focusField(i) {
      // Set focused field
      this.focusedField = {
        el: this.$refs["value-" + i][0].$refs.input,
        entry: i
      };
      // Show variables dropdown
      this.showVariables = true;
    },
    setVariable(variable) {
      // Get entries
      let entries = Object.entries(this.value);
      // Set value field to selected variable
      entries[this.focusedField.entry][1] = variable;
      // Set updated entries
      this.json = entries;
    },
    returnTypeIcon(i) {
      let value = typeCast(this.json[i][1]);
      let returnType = getReturnType(value, "any", true, true);
      return returnTypeIcons[returnType];
    },
    returnTypeText(i) {
      let value = typeCast(this.json[i][1]);
      let returnType = getReturnType(value, "any", true, true);
      let prefix = this.$t("formHelper.returnType"),
        typeText = this.$t("formHelper.returnTypes." + returnType);
      return `${prefix}: <span class="font-italic">${typeText}</span>`;
    },
    emptyOrNull(i) {
      // Return if value of row i is empty string or null
      let value = Object.entries(this.value)[i][1];
      return value === "" || value === null;
    },
    toggleEmptyType(i) {
      if (this.isDisabled) {
        return;
      }
      // Return if value is not empty string or null
      if (!this.emptyOrNull(i)) {
        return;
      }
      // Get value
      let value = Object.entries(this.value)[i][1];
      if (value === "") {
        // If value is empty string, set null
        // Here as a string, because in updateJson Method values get cast
        value = "null";
      } else if (value === null) {
        // If value is null, set empty string
        value = "";
      }
      // Update json entry
      this.updateJson(value, i, 1);
    }
  }
};
</script>
